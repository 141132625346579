import { useEffect, useState } from 'react'
import dynamic from 'next/dynamic'
import Icon from '@/components/icon/icon'
import ReactPlayer from 'react-player'
import { videoEngagementGTM } from '@/lib/gtm'

const HeroSeparateTextArea = dynamic(() => import('@/components/hero/hero-separate-text-area'))
const HeroTextOverlaysImage = dynamic(() => import('@/components/hero/hero-text-overlays-image'))
const HeroTextBeforeImage = dynamic(() => import('@/components/hero/hero-text-before-image'))

const VideoBackground = ({ video, autoplay = true }) => {
  const [isPlaying, setIsPlaying] = useState(autoplay)

   //Video handling
  const [played, setPlayed] = useState(0);
  const [duration, setDuration] = useState(0);
  const [gtmEventFired, setGtmEventFired] = useState({
    video_start: false,
    video_progress10: false,
    video_progress25: false,
    video_progress50: false,
    video_progress75: false,
    video_complete: false,
  });
  //

  const handleVideoDuration = (duration) => {
    setDuration(duration);
  }


  useEffect(() => {
    if (played && duration) {
      const percentage = (played / duration) * 100;
      const videoData = {
        video,
        played,
        duration,
        percentage
      };

      if (!gtmEventFired.video_start) {
        videoEngagementGTM(videoData, 'video_start')
        setGtmEventFired((prevState) => ({
          ...prevState,
          video_start: true,
        }));
      }

      switch (true) {
        case percentage >= 10 && percentage < 25:
          if (!gtmEventFired.video_progress10) {
            videoEngagementGTM(videoData, 'video_progress')
            setGtmEventFired((prevState) => ({
              ...prevState,
              video_progress10: true,
            }));
          }
          break;
        case percentage >= 25 && percentage < 50:
          if (!gtmEventFired.video_progress25) {
            videoEngagementGTM(videoData, 'video_progress')
            setGtmEventFired((prevState) => ({
              ...prevState,
              video_progress25: true,
            }));
          }
          break;
        case percentage >= 50 && percentage < 75:
          if (!gtmEventFired.video_progress50) {
            videoEngagementGTM(videoData, 'video_progress')
            setGtmEventFired((prevState) => ({
              ...prevState,
              video_progress50: true,
            }));
          }
          break;
        case percentage >= 75:
          if (!gtmEventFired.video_progress75) {
            videoEngagementGTM(videoData, 'video_progress')
            setGtmEventFired((prevState) => ({
              ...prevState,
              video_progress75: true,
            }));
          }
          break;
      }

      if (played >= (duration - 1)) {
        if (!gtmEventFired.video_complete) {
          videoEngagementGTM(videoData, 'video_complete')
          setGtmEventFired((prevState) => ({
            ...prevState,
            video_complete: true,
          }));
        }
      }

    }
  }, [played]);



  return (
    <ReactPlayer
      className="react-player object-cover"
      url={video.url}
      controls={true}
      playing={isPlaying}
      playsinline={true}
      light={!autoplay}
      playIcon={ <Icon
        className={`cursor-pointer text-white fill-current`}
        viewBox={`0 0 62 62`}
        size={62}
        icon="play-btn"
      />}
      loop={true}
      muted={true}
      volume={1}
      config={{
        file: {
          attributes: {
            autoPlay: autoplay,
            muted: true,
          },
        },
      }}
      width="100%"
      height="100%"
      onClick={() => setIsPlaying(!isPlaying)}
      onProgress={(progress) => setPlayed(progress.playedSeconds)}
      onDuration={handleVideoDuration}
    />
  )
}

const Hero = ({
  useVideoBackground,
  video,
  useGridLines,
  gridLinesColor,
  productData = [],
  useMediumImage = false,
  mobileBackgroundImage,
  tabletBackgroundImage,
  backgroundImage,
  isStory=false,
  ...props
}) => {
  const [headline, setHeadline] = useState(props.headline)
  const [showProductMenu, setShowProductMenu] = useState(null)
  const layout = props.layoutVersion ? props.layoutVersion : 'Separate text area' //default layout
  const autoplay = props?.autoplay || false
  const products = productData && productData.length ? productData.filter(product => product.is_visible) : []
  useEffect((showProductMenu) => {
    let isMounted = true

    if (!showProductMenu && isMounted) {
      setShowProductMenu(null)
    }

    return () => {
      isMounted = false
    }
  }, [])

  useEffect(() => {
    let isMounted = true
    let replaceString = props.headline && props.headline.replace('*', '<em>')
    if (replaceString) {
      const index = replaceString.lastIndexOf('*')
      replaceString =
        replaceString.slice(0, index) + replaceString.slice(index).replace('*', '</em>')

      if (isMounted) {
        setHeadline(replaceString)
      }
    }

    return () => {
      isMounted = false
    }
  }, [props.headline])

  const showProductList = () => {
    setTimeout(() => {
      // if (products.length > 0) {
        setShowProductMenu(true)
      // }
    }, 150)
  }

  return (
    <div className="container-xl">
      {
        layout == 'Separate text area' && <HeroSeparateTextArea
          VideoBackground={VideoBackground}
          useMediumImage={useMediumImage}
          mobileBackgroundImage={mobileBackgroundImage}
          tabletBackgroundImage={tabletBackgroundImage}
          backgroundImage={backgroundImage}
          useGridLines={useGridLines}
          gridLinesColor={gridLinesColor}
          useVideoBackground={useVideoBackground}
          video={video}
          products={products}
          showProductList={showProductList}
          showProductMenu={showProductMenu}
          setShowProductMenu={setShowProductMenu}
          headline={headline}
          isStory={isStory}
          autoplay={autoplay}
          {...props}/>
      }
      {
        layout == 'Text overlays image' && <HeroTextOverlaysImage
          VideoBackground={VideoBackground}
          useMediumImage={useMediumImage}
          mobileBackgroundImage={mobileBackgroundImage}
          tabletBackgroundImage={tabletBackgroundImage}
          backgroundImage={backgroundImage}
          useGridLines={useGridLines}
          gridLinesColor={gridLinesColor}
          useVideoBackground={useVideoBackground}
          video={video}
          products={products}
          showProductList={showProductList}
          showProductMenu={showProductMenu}
          setShowProductMenu={setShowProductMenu}
          headline={headline}
          isStory={isStory}
          autoplay={autoplay}
          {...props}/>
      }
      {
        layout == 'Text before image' && <HeroTextBeforeImage
          VideoBackground={VideoBackground}
          useMediumImage={useMediumImage}
          mobileBackgroundImage={mobileBackgroundImage}
          tabletBackgroundImage={tabletBackgroundImage}
          backgroundImage={backgroundImage}
          useGridLines={useGridLines}
          gridLinesColor={gridLinesColor}
          useVideoBackground={useVideoBackground}
          video={video}
          products={products}
          showProductList={showProductList}
          showProductMenu={showProductMenu}
          setShowProductMenu={setShowProductMenu}
          headline={headline}
          autoplay={autoplay}
          {...props}/>
      }
    </div>
  )
}

export default Hero
